@import "variables.scss";



body {
    overflow-x: hidden;
    font: normal 100% Dosis, "Century Gothic", AppleGothic, sans-serif;
    background: #eee;
}

a {
    cursor: pointer;

    &:hover,
    &:active {
        color: #171717;
    }
}

h1 {
    font-size: 1.4em;
}
h2 {
    font-size: 1.2em;
    line-height: 1;
}
h3 {
    font-size: 1em;
    font-style: italic;
    font-weight: normal;
}



/* stuff */
.success {
    background-color: #5bb75b;
}
.error {
    background-color: #ff4040;
}
.pseudo-link {
    line-height: 1;

    &:hover,
    &:active {
        color: #171717;
        border-bottom-color: #171717;
    }
}



/* animations */
.processing {
    background-size: $buttonHeight $buttonHeight;
}

@keyframes processing {
    0% { background-position: 0 0; }
    100% { background-position: $buttonHeight $buttonHeight; }
}
