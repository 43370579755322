@import "variables.scss";



.save {
    label {
        line-height: $buttonHeight;
    }
}

.save__progress {
    line-height: $buttonHeight;
}
