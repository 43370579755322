@import "variables.scss";



.menu {
    overflow: hidden;
    width: 100%;
    padding-bottom: 65px;
    user-select: none;

    &.ng-enter,
    &.ng-leave {
        position: absolute;
        transition: transform 400ms cubic-bezier(.29, .05, .43, .98);
    }

    &.ng-enter,
    &.is_backward.ng-leave.ng-leave-active {
        transform: translate(110%, 0);
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active,
    &.is_backward.ng-leave,
    &.is_backward.ng-enter.ng-enter-active {
        transform: translate(0, 0);
    }

    &.ng-leave.ng-leave-active,
    &.is_backward.ng-enter {
        transform: translate(-110%, 0);
    }
}



.menu__content {
    overflow: hidden;
    padding: 12px;
}
.menu__content__heading {
    font-size: 1.2em;
    margin: 30px 0 10px;
    padding: 8px;
    color: #fff;
}
.menu__content__title {
    margin-bottom: 5px;
}
.menu__content__separator {
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }
}
.menu__content__comment {
    margin-bottom: 10px;
}



.menu__section {
    border-bottom: 1px solid #ddd;
}
.menu__section__content {
    overflow: hidden;
    display: none;
}
.menu__section__content_open,
.menu__section__content_expand {
    display: block;
}
.menu__section__content_open-remove {
    transition: height 400ms cubic-bezier(.29, .05, .43, .98);
    display: block;
}
.menu__section__content_open-remove.menu__section__content_open-remove-active {
    height: 0;
}
.menu__section__content_expand-add,
.menu__section__content_expand-remove {
    transition: height 400ms cubic-bezier(.29, .05, .43, .98);
    display: block;
}
.menu__section__content_expand-add,
.menu__section__content_expand-remove.menu__section__content_expand-remove-active {
    height: 0;
}
