@import "variables.scss";

.vendorHomepage {
    width: 100%;
    margin-bottom: 10px;

    a {
        position: relative;
        font-size: .9em;
        display: block;
        margin-left: 15px;
        line-height: 1.2;
        text-decoration: none;
        color: #9a9a9a;

        &:hover {
            text-decoration: underline;
        }

        &:before {
            content: '\21a9';
            font-family: "iconFont";
            font-size: 17px;
            position: absolute;
            top: 0;
            left: 0;
            margin-left: -15px;
            line-height: 1;
            text-decoration: none;
        }
    }
}

.vendorLogo {
    float: left;
    margin-right: 1em;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .is_mobile & {
        float: none;
        margin-bottom: 1em;
    }
}
.vendorLogo_worth {
    width: 200px;
    height: 20px;
    background-image: url('/images/worth-logo-big.png');

    @media screen and (max-width: 540px) {
        width: 70px;
        height: 53px;
        background-image: url('/images/worth-logo-small.png');
    }
}
.vendorLogo_rawlings {
    width: 100px;
    height: 95px;
    background-image: url('/images/rawlings-custom-pro-shop-logo-300.png');

    @media screen and (max-width: 540px) {
        width: 80px;
        height: 76px;
    }
}
