@import "variables.scss";

.designSummary {
    font-size: 1em;

    dl {
        margin: .25em 0;
        line-height: 1.4;
    }

    dt {
        font-weight: bold;
    }

    dd {
        float: right;
        text-align: right;
    }
}

.designSummary__section__inner {
    margin-left: 1em;
}
