@import "variables.scss";



.icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
}
.icon_absolute_middle-left,
.icon_absolute_middle-right {
    margin-top: -7px;
}



.icon_facebook {
    color: #3b5998;
}
.icon_twitter {
    color: #00aced;
}
.icon_email {
    color: #171717;
}
.icon_google {
    color: #dd4b39;
}



.icon_reversed-on,
.icon_reversed-off {
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.icon_reversed-on {
    background-image: url('/images/reversed-on.svg');
}
.icon_reversed-off {
    background-image: url('/images/reversed-off.svg');
}

.icon_rotate-left,
.icon_rotate-right {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.icon_rotate-left {
    background-image: url('/images/arrow-rotate-left.svg');

    &:before {
        // content: "\2039"; /* Unicode Character 'SINGLE LEFT-POINTING ANGLE QUOTATION MARK' (U+2039) */
    }
}
.icon_rotate-right {
    background-image: url('/images/arrow-rotate-right.svg');

    &:before {
        // content: "\203a"; /* Unicode Character 'SINGLE RIGHT-POINTING ANGLE QUOTATION MARK' (U+203A) */
    }
}



.icon_toggle,
.icon_zoom {
    width: 100%;
    color: #000;
}
.icon_toggle {
    font-size: 28px;
    height: 28px;
}
.icon_zoom {
    font-size: 40px;
    height: 40px;
}



.icon_select {
    &:before {
        content: "\e6a1"; /* grid */
    }
}
.icon_customize,
.icon_fabricColors {
    &:before {
        content: "\e6a2"; /* brush */
    }
}
.icon_options {
    &:before {
        content: "\e6a3"; /* wrench */
    }
}
.icon_personalize,
.icon_decorate {
    &:before {
        content: "\e6a4"; /* user */
    }
}



.icon_visited {
    color: #000;
}
.icon_visited,
.icon_visited_full {
    &:before {
        content: "\25cf"; /* Unicode Character 'BLACK CIRCLE' (U+25CF) */
    }
}
.icon_visited_none,
.icon_visited_partial {
    &:before {
        content: "\25cb"; /* Unicode Character 'WHITE CIRCLE' (U+25CB) */
    }
}



.icon_order,
.icon_share,
.icon_print {
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
}
.icon_order,
.icon_share {
    margin-top: -4px;
}
.icon_print {
    margin-top: -2px;
}
