
$buttonHeight: 42px;

$tileBackground: #a9a9a9;
$tileBackgroundHover: #a6a6a6;
$tileBackgroundSelected: #ddd;

$worthPrimaryColor: #1275bc;
$worthPrimaryColorHover: #266591;
$worthPrimaryColorActive: #1f5379;

$rawlingsPrimaryColor: #de1409;
$rawlingsPrimaryColorHover: #c41108;
$rawlingsPrimaryColorActive: #8b0f08;
