@import "variables.scss";



.popup {
    box-shadow: 0 30px 30px rgba(0, 0, 0, .40);
}
.popup_standart {
    max-width: 450px;
    padding: 15px;
}

.popup__close {
    top: 15px;
    right: 15px;

    i {
        font-size: 32px;
        width: 32px;
        height: 32px;
    }
}
.popup__close_print {
    top: 15px;
    right: 30px;
}

.popup__title {
    h3 {
        margin-top: .1em;
    }
}
