@import "variables.scss";

.contextMenu {
    position: absolute;
    z-index: 1337;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
}

.contextMenu-list {
    list-style: none;
}

.contextMenu-list-item {

    padding: 8px 12px;
    background: #fff;
    color: #666;
    user-select: none;
    cursor: default;
    text-align: center;

    &:first-child {
        border-radius: 5px 5px 0 0;
    }

    &:last-child {
        border-radius: 0 0 5px 5px;
    }

    &:not(:first-child) {
        border-top: 1px solid #ccc;
    }
}

.contextMenu-list-item.mod-Clickable {

    padding: 0;

    & > a {

        width: 100%;
        display: inline-block;
        padding: 8px 12px;
        text-decoration: none;
        color: #333 !important;

        &:hover {
            text-decoration: underline;
            color: #fff !important;
        }
    }

    &:hover {
        background: #666;
    }
}
