@import "variables.scss";

@mixin columnize_tiles($columns_count) {
    @if ($columns_count == 8) {

        margin: -2% 0 0 -2%;

        & > li {
            width: 10.5%;
            margin: 2% 0 0 2%;
        }

    } @else if ($columns_count == 6) {

        margin: -2% 0 0 -2%;

        & > li {
            width: 14.65%;
            margin: 2% 0 0 2%;
        }

    } @else if ($columns_count == 5) {

        margin: -3% 0 0 -3%;

        & > li {
            width: 17%;
            margin: 3% 0 0 3%;
        }

    } @else if ($columns_count == 4) {

        margin: -4% 0 0 -4%;

        & > li {
            width: 21%;
            margin: 4% 0 0 4%;
        }

    } @else if ($columns_count == 3) {

        margin: -3% 0 0 -3%;

        & > li {
            width: 30%;
            margin: 3% 0 0 3%;
        }

    } @else if ($columns_count == 2) {

        margin: -4% 0 0 -4%;

        & > li {
            width: 46%;
            margin: 4% 0 0 4%;
        }

    }
}
