@import "variables.scss";

#worth {
    a {
        color: $worthPrimaryColor;
    }

    .theme-color {
        color: $worthPrimaryColor;
    }

    .pseudo-link {
        color: $worthPrimaryColor;
        border-bottom-color: $worthPrimaryColor;
    }

    .loading {
        &:before {
            background-image: url('/images/loading-worth.svg');
        }
    }

    .button_menu {
        &.active {
            background-color: $worthPrimaryColor;

            &:active {
                background-color: $worthPrimaryColorActive;
            }
        }

        .mouse-only & {
            &.active {
                &:hover {
                    background-color: $worthPrimaryColorHover;
                }

                &:active {
                    background-color: $worthPrimaryColorActive;
                }
            }
        }
    }

    .button_primary {
        background-color: $worthPrimaryColor;

        &:active {
            background-color: $worthPrimaryColorActive;
        }

        .mouse-only & {
            &:hover {
                background-color: $worthPrimaryColorHover;
            }

            &:active {
                background-color: $worthPrimaryColorActive;
            }
        }
    }

    .navigation__button_toggle {
        i,
        span {
            color: $worthPrimaryColor;
        }
    }

    .popup__close {
        i {
            color: $worthPrimaryColor;

            &:hover {
                color: $worthPrimaryColorHover;
            }

            &:active {
                color: $worthPrimaryColorActive;
            }
        }
    }
}

#rawlings {
    a {
        color: $rawlingsPrimaryColor;
    }

    .theme-color {
        color: $rawlingsPrimaryColor;
    }

    .pseudo-link {
        color: $rawlingsPrimaryColor;
        border-bottom-color: $rawlingsPrimaryColor;
    }

    .loading {
        &:before {
            background-image: url('/images/loading-rawlings.svg');
        }
    }

    .button_menu {
        &.active {
            background-color: $rawlingsPrimaryColor;

            &:active {
                background-color: $rawlingsPrimaryColorActive;
            }
        }

        .mouse-only & {
            &.active {
                &:hover {
                    background-color: $rawlingsPrimaryColorHover;
                }

                &:active {
                    background-color: $rawlingsPrimaryColorActive;
                }
            }
        }
    }

    .button_primary {
        background-color: $rawlingsPrimaryColor;

        &:active {
            background-color: $rawlingsPrimaryColorActive;
        }

        .mouse-only & {
            &:hover {
                background-color: $rawlingsPrimaryColorHover;
            }

            &:active {
                background-color: $rawlingsPrimaryColorActive;
            }
        }
    }

    .navigation__button_toggle {
        i,
        span {
            color: $rawlingsPrimaryColor;
        }
    }

    .popup__close {
        i {
            color: $rawlingsPrimaryColor;

            &:hover {
                color: $rawlingsPrimaryColorHover;
            }

            &:active {
                color: $rawlingsPrimaryColorActive;
            }
        }
    }

    .menu__content__heading {
        background-color: $rawlingsPrimaryColor;
    }
}

.app_uniform, .app_glove {
    .navigation__tabs {
        li {
            width: 25%;
        }
    }

    .printContainer__top {
        min-height: 80px;
    }
}

.app_worth_glove {
    .navigation__tabs {
        li {
            width: 33%;

            &:nth-child(2) {
                width: 34%;
            }
        }
    }

    .printContainer__top {
        min-height: 20px;
    }
}
