@import "variables.scss";



input,
button,
select,
textarea {
    border: 1px solid #ddd;
}

input {
    font-size: 220%;
}

input,
select,
textarea {
    &::placeholder {
        font-weight: bold;
        color: #ccc;
    }
}

.form__row {
    min-height: $buttonHeight;
}
