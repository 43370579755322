@import "variables.scss";

@media print {
    @page {
        size: portrait;
        margin: 0;
    }

    html,
    body {
        height: auto;
    }

    body {
        background: #fff;
    }

    #popupClose,
    #navigation,
    #navigationZoomButton,
    #navigationToggleButton,
    #navigationControls {
        display: none !important;
    }

    #previewArea {
        visibility: hidden; // "display:none;" will reload Flash
    }

    #lightbox {
        position: static;
        padding: 0;
        height: auto;
        background: none;
    }

    #popup {
        box-shadow: none;
    }
    #popupContent {
        height: auto;
    }

    #printContainerItems {
        position: fixed;
        left: 0;
        bottom: 20px;

        img {
            width: auto;
        }
    }
}
