@import "variables.scss";

.printContainer {
    padding: 20px;
    border-spacing: 0 10px;
}

.printContainer__top {
    transform: translate(0, -10px);
}

.printContainer__heading {
    float: left;

    p {
        margin-top: .25em;
    }
}
