@import "variables.scss";



.warning {
    p {
        margin-bottom: 20px;
    }
}

.warning__buttons {
    span {
        padding: 0 10px;
    }
}
