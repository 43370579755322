@import 'tiles.scss';

// injector
// endinjector

.previewArea {
    padding: 0;
}
.previewArea__adapter {
    padding: 0;
}

.previewContainer__inner_image {
    max-width: 500px;

    img {
        max-width: 500px;
    }
}

@media print {
    #printContainerItems {
        top: 120px;

        img {
            height: 24%;
        }
    }
}
