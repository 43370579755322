

.tiles_collection {
  li {
      float: left;

      &:nth-child(3n + 1) {
          clear: left;
      }
  }

  .tiles__title {
      width: 100%;
      margin: 0;
      padding: .3em 2px .1em;
      word-wrap: normal;
  }
}



.tiles__item_type,
.tiles__item_placement_none,
.tiles__item_placement_17LR,
.tiles__item_placement_18LR,
.tiles__item_placement_27LR,
.tiles__item_placement_28LR,
.tiles__item_fillPattern_none,
.tiles__item_fillPattern_CLSU,
.tiles__item_logo_none {
  .tiles__text {
      font-size: 20px;
  }
}


.tiles__item_filter {
  .tiles__text {
      font-size: 16px;
  }
}



.tiles__item_template {
  background-size: 100% 100%;
}



.tiles__item_logo {
  background-size: 90% 90%;
  background-position: center center;
}
.tiles__item_logo_none {
  background-image: none;
}
.tiles__item__svg_logo {
  padding: 5%;
}



.tiles__item_fillPattern {
  background-position: center;
  background-size: 102%;
}

.tiles__item_color_none {
  box-shadow: none;
  border: 1px dashed #000;

  .tiles__text {
      display: block;
  }
}



.tiles__color {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:before,
  &:after,
  .tiles__color__second,
  .tiles__color__third {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
  }
}
.tiles__color_1 {}
.tiles__color_2 {
  &:before,
  .tiles__color__second {
      left: 40%;
      width: 20%;
  }
}
.tiles__color_3 {
  &:before,
  .tiles__color__second {
      left: 0;
      width: 33%;
  }

  &:after,
  .tiles__color__third {
      right: 0;
      width: 33%;
  }
}
