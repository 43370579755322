@import "variables.scss";



.previewArea {
    position: fixed;
    top: 0;
    left: 0;
    right: 300px;
    z-index: 1;
    height: 100%;
    padding: 10px;
    background: #eee;

    &.slideLeft {
        transform: translate(-45%, 0);
    }

    .is_mobile & {
        right: 0;
    }
}

.previewArea__adapter {
    padding: 0 25px 25px;
}

.previewArea__curtain {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #eee;
}

.previewArea__logo {
    top: 10px;
    left: 10px;
}

.previewArea__title {
    bottom: 10px;
}
