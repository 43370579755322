@import "variables.scss";



.button {
    padding: 0 10px;
    line-height: $buttonHeight;
    color: #fff;
    background-color: #bbb;

    &:active {
        background-color: #999;
    }

    &.active {
        i.icon_visited {
            color: #fff;
        }
    }

    &[disabled] {
        opacity: .5;
    }

    .mouse-only & {
        &:hover {
            background-color: #aaa;
        }

        &:active {
            background-color: #999;
        }

        &[disabled] {
            &:hover,
            &:active {
                background-color: #bbb;
                background-image: none;
            }
        }
    }
}



/* button types */
.button_menu {
    display: block;
    text-align: left;
    color: #000;

    &:hover {
        color: #000;
    }

    &.active {
        color: #fff;
    }
}

.button_secondary {
    background-color: #a9a9a9;

    &:active {
        background-color: #666;
    }

    .mouse-only & {
        &:hover {
            background-color: #838383;
        }

        &:active {
            background-color: #666;
        }
    }
}

.button_squared {
    width: $buttonHeight;
    height: $buttonHeight;
    padding: 0;
}
