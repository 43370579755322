@import "variables.scss";



.uploader {
    iframe {
        width: 0;
        height: 0;
        border: 0;
        display: none;
    }

    p {
        font-style: italic;
        display: block;
        line-height: 1.3;
    }
}

.uploader__preview {
    width: 100%;
    margin-bottom: .25em;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    border: 1px dashed #dedede;
}

.uploader__browse {
    position: relative;
    display: table;
    width: 100%;
    height: $buttonHeight;
    margin-bottom: 10px;
    cursor: pointer;
    overflow: hidden;

    input[type="file"] {
        font-size: 200px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
.uploader__browse__button {
    display: table-cell;
    width: 100px;
    z-index: 1;
}
.uploader__browse__input {
    position: relative;
    display: table-cell;
    width: 100%;
    z-index: 1;

    background-color: #7f7f7f;
    &:hover {
        background-color: #737373;
    }

    span {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        margin: 0 8px;
        line-height: $buttonHeight;
        text-overflow: ellipsis;
        color: #fff;
        cursor: pointer;
    }
}

.uploader__browse__progressbar {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    transition: width 800ms ease-in-out;
}
