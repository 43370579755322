@import "variables.scss";



.share {
    padding: 10px 0 20px;
}

.share__icon {
    i {
        font-size: 80px;
        width: 80px;
        height: 80px;
        margin: -40px 0 0 -40px;
    }

    @media screen and (max-width: 420px) {
        i {
            font-size: 60px;
            width: 60px;
            height: 60px;
            margin: -30px 0 0 -30px;
        }
    }
}
