@import "variables.scss";

.scrollable {
    position: relative;
    height: 100%;
}

.scrollable__container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    min-height: 100%;
    margin-right: -8px;
    padding-right: 8px;

    -ms-touch-action: pan-y;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track-piece {
        background: #d0d0d0;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        display: block;
        background: #999;
        border-radius: 4px;

        &:hover {}
        &:active {}
    }

    &::-webkit-scrollbar-button {
        display: none;
        width: 0;
        height: 0;
    }
}

.scrollable__content {
    min-height: 100%;
    transform: translateZ(0);
}
