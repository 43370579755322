@import "variables.scss";



.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 10px;
    opacity: 1;

    transition: opacity 250ms cubic-bezier(0.550, 0.085, 0.680, 0.530);

    &.ng-hide-add,
    &.ng-hide-remove {
        display: block !important;
    }

    &.ng-hide {
        opacity: 0;
    }
}
.lightbox_black {
    background-color: #000;
    background-color: rgba(0, 0, 0, .65);
}
.lightbox_white {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .55);
}

.lightbox__wrapper {
    height: 100%;
}

.lightbox__table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    min-height: 100%;
}
.lightbox__table__cell {
    display: table-cell;
    vertical-align: middle;
}
