@import "variables.scss";

.navigation {
    width: 300px;
    height: 100%;
    display: block;

    &.is_mobile {
        width: 100%;
        display: none;
        padding-left: 50px;

        &.is_visible,
        &.is_visible-add,
        &.is_visible-remove {
            display: block;
        }

        &.is_visible-add,
        &.is_visible-remove {
            position: absolute;
            transition: transform 400ms cubic-bezier(.29, .05, .43, .98);
        }

        &.is_visible-add,
        &.is_visible-remove.is_visible-remove-active {
            transform: translate(100%, 0);
        }
        &.is_visible-remove,
        &.is_visible-add.is_visible-add-active {
            transform: translate(0, 0);
        }
    }
}

.navigation__wrapper {
    overflow: hidden;
    position: relative;
    min-height: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .20);
    background: #ccc;
}



/* buttons */
.navigation__button {
    text-align: center;
    z-index: 1;

    i,
    span {
        color: #848484;
    }

    span {
        font-size: 80%;
        display: block;
        line-height: 1.5;
    }

    .mouse-only & {
        &:hover {
            i,
            span {
                fill: #171717;
                color: #171717;
            }
        }
    }
}
.navigation__button_zoom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
}
.navigation__button_toggle {
    position: fixed;
    top: 6px;
    right: 0;
    width: 50px;
    height: 50px;

    &.is_mobile {
        display: block;
        z-index: 4;

        &.is_visible {
            height: 100%;
            background: rgba(255, 255, 255, 0);
        }

        &.is_visible,
        &.is_visible-add,
        &.is_visible-remove {
            left: 0;
            right: auto;
        }

        &.is_visible-add,
        &.is_visible-remove {
            width: 100%;
            transition: transform 400ms cubic-bezier(.29, .05, .43, .98);
        }

        &.is_visible-add,
        &.is_visible-remove.is_visible-remove-active {
            transform: translate(0, 0);

        }
        &.is_visible-remove,
        &.is_visible-add.is_visible-add-active {
            transform: translate(-100%, 0);
        }
    }
}
.navigation__button_toggle__icon {
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;

    span {
        line-height: 1.2;
    }

    &.is_mobile {
        &.is_visible-add,
        &.is_visible-remove {
            transition: transform 400ms cubic-bezier(.29, .05, .43, .98);
        }

        &.is_visible-add,
        &.is_visible-remove.is_visible-remove-active {
            transform: translate(0, 0);
        }
        &.is_visible-remove,
        &.is_visible-add.is_visible-add-active {
            transform: translate(100%, 0);
        }
    }
}
.navigation__button_viewSwitcher {
    position: absolute;
    top: 50%;
    z-index: 3;
    width: 60px;
    height: 50px;
    padding: 0 5px;
    margin-top: -25px;
}
.navigation__button_viewSwitcher_right {
    left: 0;
}
.navigation__button_viewSwitcher_left {
    right: 0;
}


.navigation__button_reversibleSwitcher {
    position: absolute;
    bottom: 0;
    z-index: 3;
    padding: 20px;
}



/* Buy & Share buttons */
.navigation__controls {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 300px;
    height: 65px;
    text-align: center;

    i {
        margin-right: 6px;
    }

    &.is_mobile {
        width: 100%;
        display: none;

        &.is_visible,
        &.is_visible-add,
        &.is_visible-remove {
            display: block;
        }

        &.is_visible-add,
        &.is_visible-remove {
            transition: transform 400ms cubic-bezier(.29, .05, .43, .98);
        }

        &.is_visible-add,
        &.is_visible-remove.is_visible-remove-active {
            transform: translate(100%, 0);
        }
        &.is_visible-remove,
        &.is_visible-add.is_visible-add-active {
            transform: translate(0, 0);
        }
    }
}
.navigation__controls__wrapper {
    height: 65px;
    padding: 10px;
    background: #fff;
    border-top: 3px solid #ddd;
    width: auto;

    .is_mobile & {
        margin-left: 50px;
    }
}



/* tabs */
.navigation__tabs {
    font-size: 12px;
    height: 60px;
    background: #2b2b2b;
    color: #fff;
    user-select: none;

    ul {
        display: table;
        width: 100%;
        height: 60px;
        list-style: none;
        border-collapse: collapse;
    }

    li {
        display: table-cell;
        line-height: 1;
        text-align: center;
        vertical-align: middle;
        border-left: 1px solid #666;
        cursor: pointer;

        &:first-child {
            border: 0;
        }

        &.active {
            background: #666;
        }

        &:active {
            background: #444;
        }
    }

    span {
        display: block;
        margin-top: 5px;
    }

    i {
        font-size: 17px;
        width: 17px;
        height: 17px;
        line-height: 17px;
    }

    .mouse-only & {
        li {
            &:hover {
                background: #555;
            }

            &:active {
                background: #444;
            }
        }
    }
}
