@import "variables.scss";

.previewContainer_flash {
    top: 36px;
    left: 140px;
    right: 140px;
    bottom: 39px;
    height: auto;
    width: auto;

    @media screen and (max-width: 540px) {
        left: 100px;
        right: 100px;
    }

    &.invisible {
        z-index: -1;
    }
}
